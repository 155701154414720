/*
 * Only for models used local to the "settings" folder.
 * For everything else use the "../models" folder.
 */
import { ThemeConfig as AntThemeConfig } from 'antd';

export enum AppFlavor {
  procivis = 'procivis',
  procivisDev = 'procivisDev',
}

export type LanguageCode = 'en' | 'de';

export interface AppConfig {
  apiBaseUrl?: string;
  availableLanguages: LanguageCode[];
  docs: string;
  flavor: AppFlavor;
  mocksEnabled: boolean;
  name: string;
  release: string;
  sentry: SentryConfig;
  slug: string;
  theme: ThemeConfig;
}

// --- Sentry

export interface SentryConfig {
  dsn?: string;
}

// --- Theme

export interface ThemeConfig {
  config: AntThemeConfig;
  images: ThemeImages;
}

export interface ThemeImages {
  loginBgURI?: string;
  logoURIs?: string[];
}
