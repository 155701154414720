import { FileTextOutlined } from '@ant-design/icons';
import PATH from '@models/constants/Path';
import { OrganisationPermission } from '@models/types/Organisation';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyCredentialListPage = lazy(
  () =>
    import(
      '@pages/dashboard/credential/CredentialListPage/CredentialListPage.adapter'
    ),
);

const LazyIssueCredentialPage = lazy(
  () =>
    import(
      '@pages/dashboard/credential/CredentialIssuePage/IssueCredentialPage.adapter'
    ),
);

const LazyCredentialDetailsPage = lazy(
  () =>
    import(
      '@pages/dashboard/credential/CredentialDetailsPage/CredentialDetailsPage.adapter'
    ),
);

const credentialsRoute: Route = {
  children: [
    {
      component: LazyIssueCredentialPage,
      id: 'issue-credential',
      path: PATH.Credentials.Issue,
      requiredPermission: OrganisationPermission.CredentialIssue,
    },
    {
      component: LazyCredentialDetailsPage,
      id: 'credential-details',
      path: PATH.Credentials.Detail(':credentialId'),
      requiredPermission: OrganisationPermission.CredentialDetail,
    },
  ],
  component: LazyCredentialListPage,
  hideInSidebar: false,
  iconComponent: FileTextOutlined,
  id: 'credentials',
  path: PATH.Credentials.List,
  requiredPermission: OrganisationPermission.CredentialList,
  title: 'route.credentials.title',
};

export default credentialsRoute;
