import { CheckCircleOutlined } from '@ant-design/icons';
import PATH from '@models/constants/Path';
import { OrganisationPermission } from '@models/types/Organisation';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const TrustManagementPage = lazy(
  () =>
    import(
      '@pages/dashboard/trustManagement/TrustManagementListPage/TrustManagementListPage.adapter'
    ),
);

const TrustManagementRoute: Route = {
  children: [],
  component: TrustManagementPage,
  hideInSidebar: false,
  iconComponent: CheckCircleOutlined,
  id: 'trust-management',
  path: PATH.TrustManagement.List,
  requiredPermission: OrganisationPermission.TrustAnchorList,
  serverSupportVerifier: (serverConfig) => !!serverConfig?.trustManagement,
  title: 'route.trustManagement.title',
  withTabs: true,
};

export default TrustManagementRoute;
