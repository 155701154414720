import { KeyOutlined } from '@ant-design/icons';
import PATH from '@models/constants/Path';
import { OrganisationPermission } from '@models/types/Organisation';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyKeyListPage = lazy(
  () => import('@pages/dashboard/keys/KeyListPage/KeyListPage.adapter'),
);
const LazyKeyCreatePage = lazy(
  () => import('@pages/dashboard/keys/KeyCreatePage/KeyCreatePage.adapter'),
);
const LazyKeyDetailsPage = lazy(
  () => import('@pages/dashboard/keys/KeyDetailsPage/KeyDetailsPage.adapter'),
);

const keysRoute: Route = {
  children: [
    {
      component: LazyKeyCreatePage,
      id: 'create-key',
      path: PATH.Keys.Create,
      requiredPermission: OrganisationPermission.KeyCreate,
    },
    {
      component: LazyKeyDetailsPage,
      id: 'key-details',
      path: PATH.Keys.Details(':keyId'),
      requiredPermission: OrganisationPermission.KeyDetail,
    },
  ],
  component: LazyKeyListPage,
  hideInSidebar: false,
  iconComponent: KeyOutlined,
  id: 'keys',
  path: PATH.Keys.List,
  requiredPermission: OrganisationPermission.KeyList,
  title: 'route.keyList.title',
};

export default keysRoute;
