import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const ApiConfigStoreModel = types
  .model('ApiConfigStore', {
    allPermissionsEnabled: types.maybe(types.boolean),
    credentialsSchemaDetailsMockEnabled: types.maybe(types.boolean),
    credentialsSchemaListMockEnabled: types.maybe(types.boolean),
    didsDeactivateMockEnabled: types.maybe(types.boolean),
    didsDetailsMockEnabled: types.maybe(types.boolean),
    didsListMockEnabled: types.maybe(types.boolean),
    keysDetailsMockEnabled: types.maybe(types.boolean),
    keysListMockEnabled: types.maybe(types.boolean),
    proofRequestListMockEnabled: types.maybe(types.boolean),
    serverConfigMockEnabled: types.maybe(types.boolean),
    trustAnchorDetailsMockEnabled: types.maybe(types.boolean),
    trustAnchorListMockEnabled: types.maybe(types.boolean),
  })

  // Sync Actions
  .actions((self) => ({
    logout: () => {
      self.allPermissionsEnabled = undefined;
      self.credentialsSchemaDetailsMockEnabled = undefined;
      self.credentialsSchemaListMockEnabled = undefined;
      self.proofRequestListMockEnabled = undefined;
      self.didsListMockEnabled = undefined;
      self.didsDetailsMockEnabled = undefined;
      self.didsDeactivateMockEnabled = undefined;
      self.keysListMockEnabled = undefined;
      self.keysDetailsMockEnabled = undefined;
      self.serverConfigMockEnabled = undefined;
      self.trustAnchorDetailsMockEnabled = undefined;
      self.trustAnchorListMockEnabled = undefined;
    },
    setAllEnabled: (enabled: boolean) => {
      self.allPermissionsEnabled = enabled;
      self.credentialsSchemaDetailsMockEnabled = enabled;
      self.credentialsSchemaListMockEnabled = enabled;
      self.proofRequestListMockEnabled = enabled;
      self.didsListMockEnabled = enabled;
      self.didsDetailsMockEnabled = enabled;
      self.didsDeactivateMockEnabled = enabled;
      self.keysListMockEnabled = enabled;
      self.keysDetailsMockEnabled = enabled;
      self.serverConfigMockEnabled = enabled;
      self.trustAnchorDetailsMockEnabled = enabled;
      self.trustAnchorListMockEnabled = enabled;
    },
    setAllPermissionsEnabled: (enabled: boolean) => {
      self.allPermissionsEnabled = enabled;
    },
    setCredentialSchemaDetailsMockEnabled: (enabled: boolean) => {
      self.credentialsSchemaDetailsMockEnabled = enabled;
    },
    setCredentialsSchemaListMockEnabled: (enabled: boolean) => {
      self.credentialsSchemaListMockEnabled = enabled;
    },
    setDidsDeactivateMockEnabled: (enabled: boolean) => {
      self.didsDeactivateMockEnabled = enabled;
    },
    setDidsDetailsMockEnabled: (enabled: boolean) => {
      self.didsDetailsMockEnabled = enabled;
    },
    setDidsListMockEnabled: (enabled: boolean) => {
      self.didsListMockEnabled = enabled;
    },
    setKeysDetailsMockEnabled: (enabled: boolean) => {
      self.keysDetailsMockEnabled = enabled;
    },
    setKeysListMockEnabled: (enabled: boolean) => {
      self.keysListMockEnabled = enabled;
    },
    setProofRequestListMockEnabled: (enabled: boolean) => {
      self.proofRequestListMockEnabled = enabled;
    },
    setServerConfigMockEnabled: (enabled: boolean) => {
      self.serverConfigMockEnabled = enabled;
    },
    setTrustAnchorDetailsMockEnabled: (enabled: boolean) => {
      self.trustAnchorDetailsMockEnabled = enabled;
    },
    setTrustAnchorListMockEnabled: (enabled: boolean) => {
      self.trustAnchorListMockEnabled = enabled;
    },
  }));

export interface ApiConfigStore extends Instance<typeof ApiConfigStoreModel> {}
export interface ApiConfigStoreSnapshot
  extends SnapshotOut<typeof ApiConfigStoreModel> {}
export interface ApiConfigStoreSnapshotIn
  extends SnapshotIn<typeof ApiConfigStoreModel> {}
