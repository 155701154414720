export const CREDENTIAL_DETAIL_ISSUE_MODAL_PARAM = 'showIssueModal';
export const PROOF_REQUEST_DETAIL_REQUEST_MODAL_PARAM = 'showRequestModal';

const getPathWithQuery = (
  path: string,
  queryParams: Record<string, string | number | boolean | undefined>,
) => {
  const query = Object.entries(queryParams)
    .filter(([, value]) => value !== undefined)
    .map(
      ([param, value]) =>
        `${param}=${encodeURIComponent(value as string | number | boolean)}`,
    )
    .join('&');
  const pathSuffix = query ? `?${query}` : '';
  return `${path}${pathSuffix}`;
};

const PATH = {
  AppVersion: {
    Detail: '/version',
  },
  Auth: {
    ForgotPassword: '/forgot-password',
    Login: '/login',
    SetPassword: '/set-password',
  },
  CredentialSchema: {
    Create: '/schema/create',
    Detail: (schemaId: string) => `/schema/${schemaId}`,
    List: '/schema',
  },
  Credentials: {
    Detail: (credentialId: string, opts: { issueModalOpen?: boolean } = {}) =>
      getPathWithQuery(`/credentials/${credentialId}`, {
        [CREDENTIAL_DETAIL_ISSUE_MODAL_PARAM]: opts.issueModalOpen,
      }),
    Issue: '/credentials/issue',
    List: '/credentials',
  },
  Did: {
    Create: '/did/create',
    Detail: (didId: string) => `/did/${didId}`,
    List: '/did',
  },
  Keys: {
    Create: '/keys/create',
    Details: (keyId: string) => `/keys/${keyId}`,
    List: '/keys',
  },
  MockSettings: {
    Detail: '/mock-settings',
  },
  Profile: {
    Detail: '/my-account',
  },
  ProofRequest: {
    Create: '/proof-request/create',
    Detail: (
      proofRequestId: string,
      opts: { requestModalOpen?: boolean } = {},
    ) =>
      getPathWithQuery(`/proof-request/${proofRequestId}`, {
        [PROOF_REQUEST_DETAIL_REQUEST_MODAL_PARAM]: opts.requestModalOpen,
      }),
    List: '/proof-request',
  },
  ProofSchema: {
    Create: '/proof-schema/create',
    Detail: (proofSchemaId: string) => `/proof-schema/${proofSchemaId}`,
    List: '/proof-schema',
  },
  Root: '/',
  TrustManagement: {
    Details: (trustAnchorId: string) => `/trust-management/${trustAnchorId}`,
    List: '/trust-management',
  },
} as const;

export default PATH;
