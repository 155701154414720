import { AppConfig } from './models';

/*
 * Export App Flavor based config
 * NOTE: Read "Environments And Flavors" in README.md for more details.
 */
const appConfigMap: Record<string, AppConfig> = {
  procivis: require('./procivis/config').default,
  procivisDev: require('./procivisDev/config').default,
};

const appConfig =
  process.env.REACT_APP_TARGET_FLAVOR === 'procivisDev'
    ? appConfigMap.procivisDev
    : appConfigMap.procivis;

export default appConfig;
